/* Home.css */

.home-container {
  max-width: 1500px; /* Avoids extremely wide lines of text for readability */
  margin: 0 auto; /* Centers the container on the page */
  padding: 20px; /* Provides some breathing room for your content */
  line-height: 1.6; /* Improved readability for long text */
  font-family: 'Helvetica Neue', sans-serif; /* Modern, easy-to-read font */
}

h1 {
  font-size: 2.5em; /* Makes the main title larger and more impactful */
  margin-bottom: 0.5em; /* Adds some space between the title and the content */
  color: #333; /* Dark color for the text, easier on the eyes */
  line-height: 1.3; /* Closer spacing for multiline titles */
}

p {
  font-size: 1.5em; /* Standard size for paragraph text */
  color: #333; /* Dark color for easier reading */
  margin-bottom: 1.5em; /* Spacing between paragraphs or elements */
  text-align: justify; /* Aligns the text evenly between the margins */
}

/* Home.css */

/* Other styles... */

.video-container {
  /* This will dictate the size of the video on mobile devices and any screen smaller than 992px */
  width: 100%;
  /* You may also want to maintain the aspect ratio for your video */
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative; /* Needed for the aspect ratio scaling */
  margin-top: 20px; /* Adds space above your video embed */
}

iframe {
  position: absolute; /* Needed for the aspect ratio scaling */
  top: 0;
  left: 0;
  width: 100%; /* Makes the iframe take the full width of the container */
  height: 100%; /* Makes the iframe take the full height of the container */
  border-radius: 10px; /* Optional: Rounds the corners of the iframe */
}

/* Media query for desktops */
@media (min-width: 992px) {
  .video-container {
    width: 75%; /* Or whatever percentage or fixed size you prefer on desktop */
    margin-left: auto; /* Centers the video container */
    margin-right: auto;
  }
}

