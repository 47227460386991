.maintenance-notice {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #555;
  }
  
  .maintenance-notice a {
    color: #007BFF; /* Adjust color as desired */
    text-decoration: none;
    margin-left: 5px;
  }
  
  .maintenance-notice a:hover {
    text-decoration: underline;
  }
  